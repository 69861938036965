import React from 'react';
import axios from 'axios';
import { Form, Input, Button, Select, notification} from 'antd';


export default class Inseriscicorso extends React.Component{
    constructor(props){
        super(props);
        this.state = {success:false,gLoading: true, name: props.match.params.id, mail: props.match.params.id+'.stud@liceocavour.gov.it'};
    
      }
      componentDidMount(){
        
        axios.get('/api/cogestione/tutti_i_corsi').then(res => {
            console.log(res);
            this.setState({corsi: res.data, gLoading:false})
          })

        }

    render(){
      
      
      const { Option } = Select;
        const layout = {
            labelCol: {
              span: 8,
            },
            wrapperCol: {
              span: 16,
            },
          };
          /* eslint-disable no-template-curly-in-string */
          
          const validateMessages = {
            required: '${label} è un campo obbligatorio',
            types: {
              email: '${label} is not a valid email!',
              number: '${label} is not a valid number!',
            },
            number: {
              range: '${label} must be between ${min} and ${max}',
            },
          };
          /* eslint-enable no-template-curly-in-string */
          const openNotification = () => {
            notification.open({
              message: 'Corso Inserito',
              description:
                'Grazie mille! Se vuoi, puoi inserire un altro corso',
             
            });
          };
          
            const onFinish = (values) => {
              console.log(values);
              this.setState({loading: true})
              
              axios.post(`/api/cogestione/crea_corso`, {values })
              .then(res => {
                console.log(res);
                this.setState({loading: false, success: true})
                openNotification();
              })
              
            };
          
            return (
              <>
              
              <h2> Inserisci un Corso</h2>
              <Form {...layout} style={{width:'80%',textAlign:'center'}}name="nest-messages" initialValues={{ name:this.state.name, email: this.state.mail}} onFinish={onFinish} validateMessages={validateMessages}>
               
                <Form.Item
                  name={'name'}
                  label="Name"
                >
                  <Input disabled={true} />
                </Form.Item>
                <Form.Item
                  name={ 'email'}
                  label="Email"
                  rules={[
                    {
                      type: 'email',
                    },
                  ]}
                >
                  <Input disabled={true}  />
                </Form.Item>
                
                <Form.Item name={'titolo'} label="Titolo" rules={[{required:true}]}>
                  <Input />
                </Form.Item>
                <Form.Item name={'descrizione'} label="Descrizione" rules={[{required:true}]}>
                  <Input.TextArea />
                </Form.Item>
                
                <center>
                  <Form.Item name={'date'} label="Data" rules={[{required:true}]}>
                  
                  <Select  style={{ width: 120 }}>
                    <Option value="25">25 Marzo</Option>
                    <Option value="26">26 Marzo</Option>
                    <Option value="27" >
                      27 Marzo
                    </Option>
                  </Select>
                  </Form.Item>
                  <Form.Item name={'time'} label="Fascia oraria" rules={[{required:true}]}>
                  
                  <Select  style={{ width: 120 }}>
                    <Option value="9:00-1">9:00 - 10:00</Option>
                    <Option value="9:00-2">9:00 - 11:00</Option>
                    <Option value="10:00-1">10:00 - 11:00</Option>
                    <Option value="11:30-1" >11:30-12:30</Option>
                    <Option value="11:30-2" >11:30-13:30</Option>
                    <Option value="12:30-1" >12:30-13:30</Option>
                  </Select>
                  </Form.Item>
                  <Form.Item label="Eventuali Ospiti" name="ospiti">
                  <Input.TextArea />
                  </Form.Item>
      <Form.Item name={'sex'} label="Il corso presenta contenuti afferenti la sfera intima e sessuale?" rules={[{required:true}]}>
                  
                  <Select  style={{ width: 120 }}>
                    <Option value={1}>Sì</Option>
                    <Option value={0}>No</Option>
                    
                  </Select>
                  </Form.Item>
      </center>
                <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>

                  <Button style={{backgroundColor: 'orange', border: '0px'}}type="primary" htmlType="submit" loading={this.state.loading}>
                    Invia
                  </Button>
                </Form.Item>
                
              </Form>
              </>
            );
          };
    
}