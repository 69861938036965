import React from 'react';
import axios from 'axios';
import { Spin, Row, Col} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export default class ProspettoClasse extends React.Component{

    constructor(props){
        super(props);
        this.state = {classe: props.match.params.id, loading:true, prospetto: []}
        
    }
    
    componentDidMount(props){
        
        axios.get('/api/cogestione/corsi_classe?classe='+this.state.classe).then(res => {
            this.setState({error: false, prospetto: res.data, loading:false})
            if(res.data==='Class not found'){
                this.setState({error: true}  )
                
            }
            else{
                this.setState({prospetto: res.data})
                
            }
          })
        
        }
  
    render(){
        
        const antIcon = <LoadingOutlined style={{ margin: 200,fontSize: 100, color:'orange' }} spin />;
        return (
            <>
        {this.state.loading && <Spin indicator={antIcon} />}
        {this.state.error && 
        <>
        <h1 style={{color:'orange', fontFamily:'Chango'}}>Classe non trovata</h1>
        <p>Questa classe non è presente sul nostro database. Se ritieni si tratti di un errore, contatta Giulio Zingrillo.</p>
        </>}
        {!this.state.error && 
         <>
         <h1 style={{color:'orange', fontFamily:'Chango'}}>Prospetto attività classe {this.state.classe}</h1>
         <br/>
         <Row style={{border:'1px solid orange'}}>
         <Col span={18} push={6} style={{fontSize:'large'}}>
           <p>{this.state.prospetto['1A']}</p>
         </Col>
         <Col span={6} pull={18} style={{fontFamily:'Chango'}}>
           25/03 09:00-10:00
         </Col>
       </Row>
       <Row style={{border:'1px solid orange'}}>
       <Col span={18} push={6} style={{fontSize:'large'}}>
         <p >{this.state.prospetto['2A']}</p>
       </Col>
       <Col span={6} pull={18}style={{fontFamily:'Chango'}}>
          10:00-11:00
       </Col>
     </Row>
     <Row style={{border:'1px solid orange'}}>
       <Col span={18} push={6} style={{fontSize:'large'}}>
         <p >{this.state.prospetto['3A']}</p>
       </Col>
       <Col span={6} pull={18}style={{fontFamily:'Chango'}}>
         11:30-12:30
       </Col>
     </Row>
     <Row style={{border:'1px solid orange'}}>
       <Col span={18} push={6} style={{fontSize:'large'}}>
         <p >{this.state.prospetto['4A']}</p>
       </Col>
       <Col span={6} pull={18}style={{fontFamily:'Chango'}}>
         12:30-13:30
       </Col>
     </Row>
     <Row style={{border:'1px solid orange'}}>
         <Col span={18} push={6} style={{fontSize:'large'}}>
           <p>{this.state.prospetto['1B']}</p>
         </Col>
         <Col span={6} pull={18} style={{fontFamily:'Chango'}}>
           26/03 09:00-10:00
         </Col>
       </Row>
       <Row style={{border:'1px solid orange'}}>
       <Col span={18} push={6} style={{fontSize:'large'}}>
         <p >{this.state.prospetto['2B']}</p>
       </Col>
       <Col span={6} pull={18}style={{fontFamily:'Chango'}}>
          10:00-11:00
       </Col>
     </Row>
     <Row style={{border:'1px solid orange'}}>
       <Col span={18} push={6} style={{fontSize:'large'}}>
         <p >{this.state.prospetto['3B']}</p>
       </Col>
       <Col span={6} pull={18}style={{fontFamily:'Chango'}}>
         11:30-12:30
       </Col>
     </Row>
     <Row style={{border:'1px solid orange'}}>
       <Col span={18} push={6} style={{fontSize:'large'}}>
         <p >{this.state.prospetto['4B']}</p>
       </Col>
       <Col span={6} pull={18}style={{fontFamily:'Chango'}}>
         12:30-13:30
       </Col>
     </Row>
     <Row style={{border:'1px solid orange'}}>
         <Col span={18} push={6} style={{fontSize:'large'}}>
           <p>{this.state.prospetto['1C']}</p>
         </Col>
         <Col span={6} pull={18} style={{fontFamily:'Chango'}}>
           27/03 09:00-10:00
         </Col>
       </Row>
       <Row style={{border:'1px solid orange'}}>
       <Col span={18} push={6} style={{fontSize:'large'}}>
         <p >{this.state.prospetto['2C']}</p>
       </Col>
       <Col span={6} pull={18}style={{fontFamily:'Chango'}}>
          10:00-11:00
       </Col>
     </Row>
     <Row style={{border:'1px solid orange'}}>
       <Col span={18} push={6} style={{fontSize:'large'}}>
         <p >{this.state.prospetto['3C']}</p>
       </Col>
       <Col span={6} pull={18}style={{fontFamily:'Chango'}}>
         11:30-12:30
       </Col>
     </Row>
     <Row style={{border:'1px solid orange'}}>
       <Col span={18} push={6} style={{fontSize:'large'}}>
         <p >{this.state.prospetto['4C']}</p>
       </Col>
       <Col span={6} pull={18}style={{fontFamily:'Chango'}}>
         12:30-13:30
       </Col>
     </Row>
     </>
       }
        
        

           </> )

    }   
}