import './App.css';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Inseriscicorso from './componenti/Inseriscicorso';
import Prospetto from './componenti/Prospetto';
import GeneralMenu from './componenti/GeneralMenu';
import ProspettoClasse from './componenti/ProspettoClasse'; 

function App() {
  return (
    <Router>
    <div className="App">
      <header style={{fontFamily: 'Chango', height:'15%', padding:'2%', backgroundColor:'orange', fontFace:'Verdana', fontSize:'x-large', marginBottom:'5%', zIndex:900, width:'100%', marginTop:0}}>
        <span style={{color:'white'}}>
        Coge
        </span>Cavour
        <span style={{color:'white', fontFamily:'Verdana'}}>| Anno 2020-21</span>
      </header>
      <GeneralMenu/>
      <div >
      
        <Switch>
          <Route path='/' exact component={Prospetto}/>
          <Route path={'/nuovo/:id'} component={Inseriscicorso}/>
          <Route path={'/prospetto'}><h1 style={{fontFamily:'Chango', color:'orange'}}>Prenotazioni Chiuse</h1><p>Contatta Giulio Zingrillo per qualunque problema</p></Route>
          <Route path={'/classe/:id'} component={ProspettoClasse}/>
          <Route>
            <h1 style={{fontFamily: 'Chango', color:'orange'}}>Pagina non trovata.</h1>
            <p>Utilizza il menu a lato per tornare alla pagina principale: se ritieni che questo sia un errore, contatta Giulio Zingrillo o Francesco Gaudio.</p>
            </Route>
        </Switch>
      
      </div>
      <footer style={{backgroundColor:'orange', fontWeight: 'bold', padding:'1%', color:'white'}}>Un progetto del Liceo Cavour di Roma. Sito di Giulio Zingrillo e Francesco Gaudio. Tutti i diritti riservati.</footer>
    </div>
    </Router>
  );
}

export default App;
