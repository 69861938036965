import React from 'react';
import { Menu } from 'antd';
import {
  BookOutlined,
  PaperClipOutlined,
  MailOutlined,
} from '@ant-design/icons';
import {Link} from 'react-router-dom';
const { SubMenu } = Menu;

export default class GeneralMenu extends React.Component {
  state = {
    collapsed: true,
  };

  

  render() {
    return (
      <div style={{ width: 256, marginTop:'5%', position:'fixed', zIndex:2}}>
        
        <Menu
          style={{backgroundColor:'yellow'}}
          defaultSelectedKeys={['1']}
          mode="vertical"
          theme="light"
          inlineCollapsed={this.state.collapsed}
        >
          <Menu.Item key="1" icon={<BookOutlined />}>
            <Link to='../'>
              Corsi
            </Link>
          </Menu.Item>
          <Menu.Item key="2" icon={<PaperClipOutlined/>} >
            <Link to='/prospetto'>
            Inserisci Prospetto
            </Link>
          </Menu.Item>
          <SubMenu key="sub1" icon={<MailOutlined />} title="Prospetti">
            <SubMenu key='sub2' title="Sezione A">
                <Menu.Item key="3"><a href='/classe/1A'>1 A</a></Menu.Item>
                <Menu.Item key="4"><a href='/classe/2A'>2 A</a></Menu.Item>
                <Menu.Item key="5"><a href='/classe/3A'>3 A</a></Menu.Item>
                <Menu.Item key="6"><a href='/classe/4A'>4 A</a></Menu.Item>
                <Menu.Item key="7"><a href='/classe/5A'>5 A</a></Menu.Item>
            </SubMenu>
            <SubMenu key='sub3' title="Sezione B">
                <Menu.Item key="8"><a href='/classe/1B'>1 B</a></Menu.Item>
                <Menu.Item key="9"><a href='/classe/2B'>2 B</a></Menu.Item>
                <Menu.Item key="10"><a href='/classe/3B'>3 B</a></Menu.Item>
                <Menu.Item key="11"><a href='/classe/4B'>4 B</a></Menu.Item>
                <Menu.Item key="12"><a href='/classe/5B'>5 B</a></Menu.Item>
            </SubMenu>
            <SubMenu key='sub4' title="Sezione C">
                <Menu.Item key="13"><a href='/classe/1C'>1 C</a></Menu.Item>
                <Menu.Item key="14"><a href='/classe/2C'>2 C</a></Menu.Item>
                <Menu.Item key="15"><a href='/classe/3C'>3 C</a></Menu.Item>
                <Menu.Item key="16"><a href='/classe/4C'>4 C</a></Menu.Item>
                <Menu.Item key="17"><a href='/classe/5C'>5 C</a></Menu.Item>
            </SubMenu>
            <SubMenu key='sub5' title="Sezione D">
                <Menu.Item key="18"><a href='/classe/1D'>1 D</a></Menu.Item>
                <Menu.Item key="19"><a href='/classe/2D'>2 D</a></Menu.Item>
                <Menu.Item key="20"><a href='/classe/3D'>3 D</a></Menu.Item>
                <Menu.Item key="21"><a href='/classe/4D'>4 D</a></Menu.Item>
                <Menu.Item key="22"><a href='/classe/5D'>5 D</a></Menu.Item>
            </SubMenu>
            <SubMenu key='sub6' title="Sezione E">
                <Menu.Item key="23"><a href='/classe/1E'>1 E</a></Menu.Item>
                <Menu.Item key="24"><a href='/classe/2E'>2 E</a></Menu.Item>
                <Menu.Item key="25"><a href='/classe/3E'>3 E</a></Menu.Item>
                <Menu.Item key="26"><a href='/classe/4E'>4 E</a></Menu.Item>
                <Menu.Item key="27"><a href='/classe/5E'>5 E</a></Menu.Item>
            </SubMenu>
            <SubMenu key='sub7' title="Sezione F">
                <Menu.Item key="28"><a href='/classe/1F'>1 F</a></Menu.Item>
                <Menu.Item key="29"><a href='/classe/2F'>2 F</a></Menu.Item>
                <Menu.Item key="30"><a href='/classe/3F'>3 F</a></Menu.Item>
                <Menu.Item key="31"><a href='/classe/4F'>4 F</a></Menu.Item>
                <Menu.Item key="32"><a href='/classe/5F'>5 F</a></Menu.Item>
            </SubMenu>
            <SubMenu key='sub8' title="Sezione G">
                <Menu.Item key="33"><a href='/classe/1G'>1 G</a></Menu.Item>
                <Menu.Item key="34"><a href='/classe/2G'>2 G</a></Menu.Item>
                <Menu.Item key="35"><a href='/classe/3G'>3 G</a></Menu.Item>
                <Menu.Item key="36"><a href='/classe/4G'>4 G</a></Menu.Item>
                <Menu.Item key="37"><a href='/classe/5G'>5 G</a></Menu.Item>
            </SubMenu>
            <SubMenu key='sub9' title="Sezione H">
                <Menu.Item key="38"><a href='/classe/1H'>1 H</a></Menu.Item>
                <Menu.Item key="39"><a href='/classe/2H'>2 H</a></Menu.Item>
                <Menu.Item key="40"><a href='/classe/3H'>3 H</a></Menu.Item>
                <Menu.Item key="41"><a href='/classe/4H'>4 H</a></Menu.Item>
            </SubMenu>
            <SubMenu key='sub10' title="Sezione I">
                <Menu.Item key="42"><a href='/classe/1I'>1 I</a></Menu.Item>
                <Menu.Item key="43"><a href='/classe/2I'>2 I</a></Menu.Item>
                <Menu.Item key="44"><a href='/classe/3I'>3 I</a></Menu.Item>
                <Menu.Item key="45"><a href='/classe/4I'>4 I</a></Menu.Item>
                <Menu.Item key="46"><a href='/classe/5I'>5 I</a></Menu.Item>
            </SubMenu>
          </SubMenu>
        </Menu>
      </div>
    );
  }
}