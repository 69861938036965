import React from 'react';
import axios from 'axios';
import { Spin,  List, Card, Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import img from '../logocavour.jpeg'


export default class Prospetto extends React.Component{
    constructor(props){
        super(props);
        this.state = {loading:true, corsi: [], modalvisible:false, displayBody: '', displayHead:''}
        this.handleCancel = this.handleCancel.bind(this);
        this.apriModello = this.apriModello.bind(this);
    }
    componentDidMount(){
        
        axios.get('/api/cogestione/tutti_i_corsi').then(res => {
            
            this.setState({corsi: res.data, loading:false})
          })

      }
      handleCancel(){
        this.setState({modalvisible: false});
      }
      apriModello(item){
        Modal.info({
          okButtonProps: {style: {backgroundColor:'orange'}},
          icon: <img alt='Logo Cavour'style={{marginLeft:'35%',width:'30%'}}src='https://zingrillo.altervista.org/img/LOGO%20CAVOUR.png'/>,
          title: item.giorno+"/03, ore "+item.orai+"-"+item.oraf,
          content: (
            <div>
              <b>{item.titolo}</b> di {item.nome_cognome_responsabile}
              <p>{item.descrizione}</p>
              <small>Per maggiori informazioni, contatta i Rappresentanti o il Comitato Misto</small>
            </div>
          ),
          onOk() {},
        });
        //this.setState({modalvisible: true})
      }
    render(){

        const antIcon = <LoadingOutlined style={{ margin: 200,fontSize: 100, color:'orange' }} spin />;
        return (
            <div>
            {this.state.loading && <Spin indicator={antIcon} />}
            {!this.state.loading &&
          <div>  
            <h1 style={{color:'orange', fontFamily: 'Chango'}}>Corsi</h1>
            <List
    grid={{ gutter: 16, column: 3 }}
    dataSource={this.state.corsi}
    renderItem={item => (
      <List.Item onClick={()=>this.apriModello(item)}>
        <Card  
        bodyStyle = {{overflow: 'hidden'}}
        title={item.giorno+"/03, ore "+item.orai+"-"+item.oraf}><b>{item.titolo}</b>, di {item.nome_cognome_responsabile}</Card>
      </List.Item>)}
  />
      </div>
}
   
            </div>
        );
    }
}